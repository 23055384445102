.header {
    position: relative;
    height: 176px;

    @include media-queries(1280px) {
        height: 250px;
    }

    @include media-queries(1700px) {
        height: 275px;
    }

    @include media-queries(2400px) {
        height: 400px;
    }

    &__logo {
        position: absolute;
        display: inline-block;
        top: 38px;
        left: 50%;
        transform: translateX(-50%);
        background: url('../img/logo.svg') no-repeat center center;
        background-size: contain;
        width: 150px;
        height: 100px;
        h1 {
            text-indent: -9999px;
            margin: 0;
            padding: 0;
        }

        @include media-queries(1280px) {
            top: 50px;
            width: 250px;
            height: 150px;
        }

        @include media-queries(1700px) {
            top: 50px;
            width: 275px;
            height: 175px;
        }

        @include media-queries(2400px) {
            top: 100px;
            width: 300px;
            height: 200px;
        }
    }
}
