// colors
$black: #000;
$white: #FAFAFA;
$silver: #f0efee;
$dark-silver: #e2e0de;
$gold: #ab842e;
$blue: #041240;


$red: #9d442e;
$gray: #e5e6e7;
