$font-size: 55%;
$RelativeRegular: "Relative-Regular", sans-serif;
$RelativeBold: "Relative-Bold", sans-serif;

@font-face {
    font-family: "Relative-Regular";
    src: url("../fonts/Relative-10Pitch.eot?") format("eot"),
    url("../fonts/relative-10pitch-webfont") format("woff2"),
    url("../fonts/relative-10pitch-webfont.woff") format("woff"),
    url("../fonts/Relative-10Pitch.ttf") format("truetype"),
    url("../fonts/Relative-10Pitch.svg#Relative-Regular") format("svg");
}

@font-face {
    font-family: "Relative-Bold";
    src: url("../fonts/Relative-Bold.eot?") format("eot"),
    url("../fonts/relative-bold-webfont.woff2") format("woff2"),
    url("../fonts/relative-bold-webfont.woff") format("woff"),
    url("../fonts/Relative-Bold.ttf") format("truetype"),
    url("../fonts/Relative-Bold.svg#Relative-Bold") format("svg");
}

a {
    color: $white;
    text-decoration: none;

    &.active {
        position: relative;
        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            left: 0;
            bottom: 5px;
            background-color: $white;
        }
    }
}

h2 {
  font-family: $RelativeBold;
}
