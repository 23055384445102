.footer-element {
  width: 150px;
  display: block;
  margin: auto;
  margin-bottom: 50px;

  &:hover {
    cursor: pointer;
  }

  @include media-queries(1280px) {
    width: 200px;
    margin-bottom: 0;
  }

  @include media-queries(1700px) {
    width: 275px;
    margin-bottom: 0;
  }

  @include media-queries(2400px) {
    width: 325px;
    margin-bottom: 0;
  }

}

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  @include box-sizing(border-box);

  @include media-queries(1280px) {
    flex-direction: row;
  }

  &__contact {
    @include box-sizing(border-box);

    @include media-queries(1280px) {
      width: 100%;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    margin: 35px 0 30px 0;

    @include media-queries(1280px) {
      margin: 0 0 10px 0;
    }

    @include media-queries(1700px) {
      margin: 0 0 50px 0;
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: $black;
      font-size: 1.6rem;
      line-height: 1.5;
      margin: auto;
      margin-bottom: 20px;
      text-align: center;

      @include media-queries(1280px) {
        font-size: 1.5rem;
        width: 25%;
        margin: auto;
        margin-bottom: 50px;
      }

      @include media-queries(1700px) {
        font-size: 1.8rem;
      }

      @include media-queries(2400px) {
        font-size: 2.2rem;
        margin: auto;
        margin-bottom: 75px;
      }

      strong {
        display: block;
        font-weight: 700;
      }

      a {
        position: relative;
        color: $black;
        display: inline-block;
        align-self: center;

        &:last-of-type {
          &:hover {
            &:after {
              width: 100%;
            }
          }
        }

        &:last-of-type {
          &:after {
            @include media-queries(1280px) {
              position: absolute;
              content: "";
              height: 1px;
              left: 0;
              bottom: -5px;
              background-color: $black;
              width: 0;

              @include transition(width 0.3s ease-in-out);
            }

            @include media-queries(2400px) {
              height: 2px;
            }
          }
        }
      }
    }
  }

  &__team {
    width: 44%;
    align-self: center;

    p {
      color: $black;
      font-size: 4.5rem;
      text-align: center;
      margin: 0 0 148px 0;
      display: block;

      @include media-queries(1280px) {
        display: block;
        font-size: 3rem;
        margin: 0 0 66px 0;
      }

      @include media-queries(1700px) {
        font-size: 3.8rem;
        margin: 0 0 92px 0;
      }

      @include media-queries(2400px) {
        font-size: 4.5rem;
        margin: 0 0 148px 0;
      }
    }
  }

  &__brands {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    &__item {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 50%;
      align-items: center;

      @include box-sizing(border-box);

      &__img--1 {
        background: url("../img/icon-jll.svg") no-repeat left center;
        background-size: contain;
        width: 68px;
        height: 30px;
        margin: 0 0 37px 0;

        @include media-queries(1280px) {
          width: 118px;
          height: 52px;
          margin: 0 0 75px 0;
        }

        @include media-queries(1700px) {
          width: 157px;
          height: 70px;
          margin: 0 0 100px 0;
        }

        @include media-queries(2400px) {
          width: 190px;
          height: 84px;
          margin: 0 0 100px 0;
        }
      }

      &__img--2 {
        background: url("../img/icon-savanna.svg") no-repeat left center;
        background-size: contain;
        width: 41px;
        height: 42px;
        margin: 0 0 37px 0;

        @include media-queries(1280px) {
          width: 75px;
          height: 76px;
          margin: 0 0 75px 0;
        }

        @include media-queries(1700px) {
          width: 100px;
          height: 101px;
          margin: 0 0 100px 0;
        }

        @include media-queries(2400px) {
          width: 116px;
          height: 118px;
          margin: 0 0 100px 0;
        }
      }
    }
  }

  &__credit {
    width: 100%;
    @include box-sizing(border-box);
    // @include media-queries(1280px) {
    //   width: 100%;
    // }
  }

  &__credits {
    p {
      color: $black;
      font-size: 1rem;
      text-align: center;
      line-height: 1.25rem;
      margin: 0 0 37px 0;
      display: block;

      @include media-queries(1280px) {
        display: block;
        font-size: 1.5rem;
        line-height: 1.8rem;
        margin: 0 0 75px 0;
      }

      @include media-queries(1700px) {
        font-size: 1.8rem;
        line-height: 2.1rem;
        margin: 0 0 130px 0;
      }

      @include media-queries(2400px) {
        font-size: 2.2rem;
        line-height: 2.7rem;
        margin: 0 0 130px 0;
      }
    }

    a {
      position: relative;
      color: $black;
      display: inline-block;
      align-self: center;

      &:last-of-type {
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      &:last-of-type {
        &:after {
          @include media-queries(1280px) {
            position: absolute;
            content: "";
            height: 1px;
            left: 0;
            bottom: -5px;
            background-color: $black;
            width: 0;

            @include transition(width 0.3s ease-in-out);
          }

          @include media-queries(2400px) {
            height: 2px;
          }
        }
      }
    }
  }


.footer-bottom {
  width: 100%;
  height: 180px;
  position: relative;

  border-bottom: $gray solid 6px;

  @include media-queries(1280px) {
    height: 250px;
    border-bottom: $gray solid 17px;
  }

  @include media-queries(1700px) {
    height: 300px;
    border-bottom: $gray solid 23px;
  }


  @include media-queries(2400px) {
    height: 400px;
    border-bottom: $gray solid 31px;
  }
}


.footer-bottom__element {
  width: 75px;
  display: block;
  margin: auto;

  @include media-queries(1280px) {
    width: 100px;
  }

  @include media-queries(1700px) {
    width: 150px;
  }

  @include media-queries(2400px) {
    width: 180px;
  }
  &.rea {
    width: 300px;
    text-align: center;
    margin-top: 25px;
    font-size: 1.2rem;
    a {
      color: $black;
    }
    @include media-queries(1280px) {
      font-size: 1.5rem;
    }

    @include media-queries(1700px) {
      font-size: 1.8rem;
    }

    @include media-queries(2400px) {
      font-size: 2.2rem;
    }
  }
}


}
