body, html {
    width: 100%;
    min-height: 100% !important;
}

html {
    font-family: $RelativeRegular;
    font-size: $font-size;
    -webkit-font-smoothing: antialiased;
}

body {
    background-color: $white;
    color: $black;
    padding: 0;
    font-weight: 400;
    border-left: $gray solid 6px;
    border-right: $gray solid 6px;

    @include box-sizing(border-box);

    @include media-queries(1280px) {
        border-left: $gray solid 17px;
        border-right: $gray solid 17px;
    }

    @include media-queries(1700px) {
        border-left: $gray solid 23px;
        border-right: $gray solid 23px;
    }

    @include media-queries(2400px) {
        border-left: $gray solid 31px;
        border-right: $gray solid 31px;
    }
}

.container {
    padding-left: 20px;
    padding-right: 20px;

    @include media-queries(1280px) {
        padding-left: 70px;
        padding-right: 70px;
    }

    @include media-queries(1700px) {
        padding-left: 92px;
        padding-right: 92px;
    }

    @include media-queries(2400px) {
        padding-left: 135px;
        padding-right: 135px;
    }
}

.full-size {
    padding-left: 0;
    padding-right: 0;

    @include media-queries(1280px) {
        padding-left: 70px;
        padding-right: 70px;
    }

    @include media-queries(1700px) {
        padding-left: 92px;
        padding-right: 92px;
    }

    @include media-queries(2400px) {
        padding-left: 135px;
        padding-right: 135px;
    }
}

.bg-silver {
    background-color: $dark-silver;
}

.border {
    &--top {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 50%;
            height: 6px;
            background-color: $gray;

            @include media-queries(1280px) {
                height: 17px;
            }

            @include media-queries(1700px) {
                height: 23px;
            }

            @include media-queries(2400px) {
                height: 31px;
            }

        }

        &:after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 50%;
            height: 6px;
            background-color: $gray;

            @include media-queries(1280px) {
                height: 17px;
            }

            @include media-queries(1700px) {
                height: 23px;
            }

            @include media-queries(2400px) {
                height: 31px;
            }
        }
    }
}

.overlay {
    display: none;

    .ie9 &,
    .ie10 &,
    .safari & {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $silver;
        color: $gray;
        font-family: $RelativeRegular;
        font-size: 3rem;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            @include translate(-50%, -50%);
        }
    }
}
