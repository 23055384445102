.download {
    background-color: $red;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 52px 0 42px 0;
    padding: 27px 0 32px 0;

    @include media-queries(1280px) {
        margin: 100px 0;
        padding: 46px 0 54px 0;
    }

    @include media-queries(1700px) {
        margin: 150px 0 150px 0;
        padding: 60px 0 68px 0;
    }

    @include media-queries(2400px) {
        margin: 175px 0;
        padding: 90px 0 105px 0;
    }

    h2 {
        width: 100%;
        color: $white;
        font-size: 1.6rem;
        font-weight: 600;
        text-align: center;
        margin: 0 0 52px 0;

        @include media-queries(1280px) {
            font-size: 3rem;
            margin: 0 0 70px 0;
        }

        @include media-queries(1700px) {
            font-size: 3.8rem;
            margin: 0 0 94px 0;
        }

        @include media-queries(2400px) {
            font-size: 4.5rem;
            margin: 0 0 130px 0;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;


        a {
          position: relative;
          color: $white;
          display: inline-block;
          align-self: center;

          color: $white;
          font-size: 1.2rem;
          line-height: 1.2;
          text-align: center;

          @include media-queries(1280px) {
              font-size: 1.8rem;
          }

          @include media-queries(1700px) {
              font-size: 2.4rem;
          }

          @include media-queries(2400px) {
              font-size: 2.8rem;
          }


          &:last-of-type {
            &:hover {
              &:after {
                width: 100%;
              }
            }
          }

          &:last-of-type {
            &:after {
              @include media-queries(1280px) {
                position: absolute;
                content: "";
                height: 1px;
                left: 0;
                bottom: -5px;
                background-color: $white;
                width: 0;

                @include transition(width 0.3s ease-in-out);
              }

              @include media-queries(2400px) {
                height: 2px;
              }
            }
          }
        }

    }
}
