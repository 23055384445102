.slider {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    background-color: $white;
    @include box-sizing(border-box);

    &__img {
        @include media-queries(1280px) {
            display: none !important;
        }
    }

    @include media-queries(1280px) {
        height: calc(100vh - 208px);
    }

    @include media-queries(1700px) {
        height: calc(100vh - 275px);
    }

    @include media-queries(2400px) {
        height: calc(100vh - 400px);
    }

    &__item {
        width: 100%;
        margin: 0;
        padding: 0;

        .ie9 &,
        .ie10 &,
        .ie11 & {
            &:first-of-type {
                display: block;
            }

            display: none;
        }

        @include media-queries(1280px) {
            height: calc(100vh - 208px);
        }

        @include media-queries(1700px) {
            height: calc(100vh - 275px);
        }

        @include media-queries(2400px) {
            height: calc(100vh - 400px);
        }

        &--2 {
            @include media-queries(1280px) {
                background: url('https://s3.amazonaws.com/reacdn/888-broadway/renderings/888_BW_Hero_2400px.jpg') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }

        &--3 {
            @include media-queries(1280px) {
                background: url('https://s3.amazonaws.com/reacdn/888-broadway/renderings/888_BW_Entrance_2400px.jpg') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }

        &--4 {
            @include media-queries(1280px) {
                background: url('https://s3.amazonaws.com/reacdn/888-broadway/renderings/888_View_1_2400px.jpg') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }

        &--5 {
            @include media-queries(1280px) {
                background: url('https://s3.amazonaws.com/reacdn/888-broadway/renderings/888_View-2_2400px.jpg') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }

        &--6 {
            @include media-queries(1280px) {
                background: url('https://s3.amazonaws.com/reacdn/888-broadway/renderings/888_BW_Level_6_Reception_2400px.jpg') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }

        &--7 {
            @include media-queries(1280px) {
                background: url('https://s3.amazonaws.com/reacdn/888-broadway/renderings/888_BW_Office_Soft_Seating_2400px.jpg') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }

        &--8 {
            @include media-queries(1280px) {
                background: url('https://s3.amazonaws.com/reacdn/888-broadway/renderings/888_BW_Conference_Room_2400px.jpg') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }

        &--9 {
            @include media-queries(1280px) {
                background: url('https://s3.amazonaws.com/reacdn/888-broadway/renderings/888_BW_Penthouse_Out_2400px.jpg') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }

        &--10   {
            @include media-queries(1280px) {
                background: url('https://s3.amazonaws.com/reacdn/888-broadway/renderings/888_BW_Penthouse_In_2400px.jpg') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }



        &--caption {
            position: absolute;
            left: 0;
            bottom: -25px;
            color: $black;
            font-size: 1.2rem;

            @include media-queries(1280px) {
                bottom: -40px;
                font-size: 1.8rem;
            }

            @include media-queries(1700px) {
                font-size: 2.2rem;
            }

            @include media-queries(2400px) {
                bottom: -60px;
                font-size: 2.8rem;
            }
        }
    }
}

.section-small-slider {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &--fix {
        visibility: hidden;
    }
}

.small-slider__description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    color: $black;
    font-size: 1.8rem;
    line-height: 1.214;
    text-align: center;
    margin-top: 58px;
    position: relative;
    @include box-sizing(border-box);

    order: 2;

    ul {
      list-style-type: none;
    }

    a {
        font-size: 1.6rem;
        position: relative;
        color: $red;
        display: inline-block;
        align-items: center;
        margin-top: 20px;
    }

    @include media-queries(1280px) {
        width: 35%;
        font-size: 2rem;
        padding-left: 60px;
        margin-top: 0;
        text-align: left;


        order: 2;

        span {
          position: absolute;
          bottom: 0;
        }

        a {
            font-size: 2rem;
            align-self: flex-start;
            &:hover {
                &:after {
                    width: 100%;
                }
            }

            &:after {
                width: 0;
                position: absolute;
                content: '';
                height: 1px;
                left: 0;
                bottom: -5px;
                background-color: $red;
                @include transition(width .3s ease-in-out);
            }
        }
    }

    @include media-queries(1700px) {
        font-size: 2.4rem;
        line-height: 1.3333;
        padding-left: 60px;

        span {
          position: absolute;
          bottom: 0;
        }


        a {
            font-size: 2.4rem;
        }
    }

    @include media-queries(2400px) {
        width: 35%;
        font-size: 2.8rem;
        padding-left: 130px;

        span {
          position: absolute;
          bottom: 0;
        }


        a {
            font-size: 2.8rem;
        }
    }
}

.small-slider {
    width: 100% !important;
    margin: 0;
    padding: 0;
    -webkit-transform:translateZ(0);
    -webkit-backface-visibility:hidden;

    order: 1;

    @include media-queries(1280px) {
        width: 65% !important;
        order: 1;
    }

    &__item {
        position: relative;
        width: 100%;
        margin: 0 0 20px 0;
        padding: 0;
        background-color: white;

        .ie9 &,
        .ie10 &,
        .ie11 & {
            &:first-of-type {
                display: block;
            }

            display: none;
        }

        img {
            width: 100%;
            -webkit-transform:translateZ(0);
            -webkit-backface-visibility:hidden;
        }

        @include media-queries(1280px) {
            margin: 0 0 40px 0;

            order: 2;
        }

        @include media-queries(2400px) {
            margin: 0 0 60px 0;

            order: 2;
        }

        &--caption {
            position: absolute;
            left: 0;
            bottom: -20px;
            color: $black;
            font-size: 1.2rem;

            @include media-queries(1280px) {
                bottom: -40px;
                font-size: 1.8rem;
            }

            @include media-queries(1700px) {
                font-size: 2.2rem;
            }

            @include media-queries(2400px) {
                bottom: -60px;
                font-size: 2.8rem;
            }
        }
    }


}

.big-slider {
    width: 100%;
    @include box-sizing(border-box);

    &__item {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0;
        margin: 0 0 20px 0px;

        .ie9 &,
        .ie10 &,
        .ie11 & {
            &:first-of-type {
                display: block;
            }

            display: none;
        }

        img {
            width: 100%;
        }

        @include media-queries(1280px) {
            margin: 0 0 40px 0px;
        }

        @include media-queries(2400px) {
            margin: 0 0 60px 0px;
        }

        &--caption {
            position: absolute;
            left: 0;
            bottom: -20px;
            color: $black;
            font-size: 1.2rem;

            @include media-queries(1280px) {
                bottom: -40px;
                font-size: 1.8rem;
            }

            @include media-queries(1700px) {
                font-size: 2.2rem;
            }

            @include media-queries(2400px) {
                bottom: -60px;
                font-size: 2.8rem;
            }
        }
    }
}
